import React from 'react';
import info from '../../data/section.json';
import Map from '../global-components/Map';
import Contact from './Contact';

const MapContactWrapperV2 = () => {
  let data = info.sectionData.mapDescription;
  return (
    <>
      <section className="contact contact-page">
        {/* <!--====================== contact information ======================--> */}
        <Contact />
        {/* <!--====================== contact information ======================--> */}

        {/* <!--================= map =================--> */}
        <div className="contact-map" id="map">
          <Map
            let={data.let}
            lng={data.lng}
            zoom={data.zoom}
            mapLetLng={data.mapLetLng.center}
          />
        </div>
        {/* <!--====================== End of map ======================--> */}
      </section>
    </>
  );
};

export default MapContactWrapperV2;
