import React from 'react';
import { useTranslation } from 'react-i18next';
import info from './../../data/section.json';

const Faq = () => {
  const { t } = useTranslation();

  let data = info.sectionData.faq;

  const faqTranslated = [
    { title: t('faq1_question'), content: t('faq1_answer') },
    { title: t('faq2_question'), content: t('faq2_answer') },
    { title: t('faq3_question'), content: t('faq3_answer') },
    { title: t('faq4_question'), content: t('faq4_answer') },
    { title: t('faq5_question'), content: t('faq5_answer') }
  ];

  const faqs = data.singleFaq.map((faq, i) => {
    return { ...faq, ...faqTranslated[i] };
  });

  return (
    <>
      {/* <!-- faq --> */}
      <section className="faq pb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              {/* <!-- faq content --> */}
              <div
                className="faq-content wow fadeInUp"
                data-wow-delay=".2s"
                data-wow-duration="1.5s"
              >
                <h2>{t('faq_title')}</h2>
                <p>{t('faq_text')}</p>
                <div className="accordion-wrapper">
                  <div id="accordion">
                    {faqs.map((faq, i) => {
                      return (
                        <div
                          key={i}
                          className="panel wow fadeInUp"
                          data-wow-delay={`${i * 0.2}s`}
                          data-wow-duration="1.5s"
                        >
                          <div className="panel-header">
                            <button
                              className={`btn ${
                                faq.status === false ? 'collapsed' : ''
                              }`}
                              data-toggle="collapse"
                              data-target={`#faq${i + 1}`}
                              aria-expanded="true"
                            >
                              <span className="checkbox">
                                <i className="flaticon-check"></i>
                              </span>
                              {faq.title}
                              <span className="arrow">
                                <i className="flaticon-down-arrow"></i>
                              </span>
                            </button>
                          </div>

                          <div
                            id={`faq${i + 1}`}
                            className={`collapse ${
                              faq.status === true ? 'show' : ''
                            }`}
                            aria-labelledby="headingOne"
                            data-parent="#accordion"
                          >
                            <div className="panel-body">{faq.content}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {/* <!-- End of faq content --> */}
            </div>
            <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-0">
              <div
                className="faq-video wow fadeInRight"
                data-wow-delay=".3s"
                data-wow-duration="1.5s"
              >
                <img src={data.faqVideo.background} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End of faq --> */}
    </>
  );
};
export default Faq;
