import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import info from './../../data/section.json';

const Footer = () => {
  let data = info.sectionData.footer;
  const { t } = useTranslation();

  return (
    <>
      {/* <!--======================== footer ========================--> */}

      <footer
        className="pt-120"
        style={{ background: `url(../../assets/img/footer.jpg)` }}
      >
        {/* <!--======================== wanna contact ========================--> */}
        <div className="subscription">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/* <!--======================== wanna contact ========================--> */}
                <div className="wanna-contact">
                  <h2>{t('footer_title')}</h2>
                  <Link className="btn" to="/contact">
                    {t('start')}
                  </Link>
                </div>
                {/* <!--======================== End of wanna contact ========================--> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!--======================== End of contact ========================--> */}

        {/* <!--======================== footer content ========================--> */}
        <div className="footer-main-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                {/* <!--======================== single footer items ========================--> */}
                <div className="single-footer-items">
                  <div className="items-header">
                    <h4>{t('about')}</h4>
                  </div>
                  <div className="about-items-content">
                    <p>{t('about_text')}</p>
                  </div>
                </div>
                {/* <!--======================== End of single footer items ========================--> */}
              </div>

              <div className="col-lg-4 col-md-6">
                {/* <!--======================== single footer items ========================--> */}
                <div className="single-footer-items">
                  <div className="items-header">
                    <h4>{t('pages')}</h4>
                  </div>
                  {/* <!--======================== service items ========================--> */}
                  <div className="footer-service">
                    <ul>
                      <li>
                        <Link to="/">{t('home')}</Link>
                      </li>
                      <li>
                        <Link to="/service">{t('services')}</Link>
                      </li>
                      <li>
                        <Link to="/about">{t('about')}</Link>
                      </li>
                      <li>
                        <Link to="/contact">{t('contact')}</Link>
                      </li>
                    </ul>
                  </div>
                  {/* <!--======================== End of service items ========================--> */}
                </div>
                {/* <!--======================== End of single footer items ========================--> */}
              </div>

              <div className="col-lg-4 col-md-6">
                {/* <!--======================== single footer items ========================--> */}
                <div className="single-footer-items">
                  <div className="items-header">
                    <h4>{t('working_hours')}</h4>
                  </div>
                  {/* <!--======================== time option ========================--> */}
                  <div className="footer-time-option">
                    <ul>
                      <li>
                        <p>{t('monday')}</p>
                        <span>10:00 – 19:00</span>
                      </li>
                      <li>
                        <p>{t('tuesday')}</p>
                        <span>10:00 – 19:00</span>
                      </li>
                      <li>
                        <p>{t('wednesday')}</p>
                        <span>10:00 – 19:00</span>
                      </li>
                      <li>
                        <p>{t('thursday')}</p>
                        <span>10:00 – 19:00</span>
                      </li>
                      <li>
                        <p>{t('friday')}</p>
                        <span>10:00 – 19:00</span>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- End of time option --> */}
                </div>
                {/* <!-- End of single footer items --> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!--======================== End of footer content ========================--> */}

        {/* <!--======================== footer bottom ========================--> */}
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="footer-bottom-content">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="copy-right">
                        <p>
                          Copyright {data.footerBottom.copyRightYear} All Right
                          Reserved
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--======================== End of footer bottom ========================--> */}
      </footer>
      {/* <!--======================== End of footer ========================--> */}
    </>
  );
};
export default Footer;
