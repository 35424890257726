import { React } from 'react';
import AppRoute from '../components/route-components/Approute';

function App() {
  return (
   <AppRoute />
  );
}

export default App;
