import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { useTranslation } from 'react-i18next';

const lngs = {
  // en: { nativeName: "English" },
  am: { nativeName: 'Հայերեն' },
  ru: { nativeName: 'Русский' }
};

const NavigationMenu = (props) => {
  const [addStickyClass, setAddStickyClass] = useState(' ');
  const [scrollTop, setScrollTop] = useState(0);
  const [navHide, setNavHide] = useState(false);
  const myRef = React.createRef();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      if (scrollTop > 200) {
        setAddStickyClass('make_me_sticky');
      } else {
        // setAddStickyClass('make_me_sticky')
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  useEffect(() => {
    myRef.current.scrollTo(0, 0);
  }, [myRef]);

  return (
    <>
      {/* <!-- Start header area --> */}
      <header
        className={`${addStickyClass} ${
          props.isOpen ? 'video-modal-open' : 'video-modal-close'
        }`}
        ref={myRef}
      >
        {/* <!-- header top --> */}
        <div className="header-top">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-4 d-none d-lg-block">
                {/* <!-- logo box --> */}
                <div className="logo-box">
                  <Link to="/">
                    <img src="assets/img/logo.png" alt="logo" />
                  </Link>
                </div>
                {/* <!-- End of logo box --> */}
              </div>
              <div className="col-lg-6 d-none d-md-block">
                {/* <!-- header meta --> */}
                <div className="header-meta">
                  <ul className="meta-list">
                    <li>
                      <span className="icon">
                        <i className="flaticon-phone-call"></i>
                      </span>
                      <div className="meta-content">
                        <p>{t('phone')}</p>
                        <span>{t('phone_1')}</span>
                      </div>
                    </li>
                    <li>
                      <span className="icon">
                        <HashLink to="/contact#map">
                          <i className="flaticon-placeholder"></i>
                        </HashLink>
                      </span>
                      <div className="meta-content">
                        <HashLink to="/contact#map">
                          <p>{t('location')}</p>
                          <span>
                            {t('address_1')}, {t('address_2')}
                          </span>
                        </HashLink>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* <!-- End of header meta --> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End of header top --> */}
        <div className="menu-inner-area">
          <div className="container">
            <div className="row align-items-center position-relative">
              <div className="col-md-3 col-lg-2 col-5">
                {/* <!-- logo box --> */}
                <div className="logo-box">
                  <Link to="/">
                    <img src="assets/img/logo.png" alt="logo" />
                  </Link>
                </div>
                {/* <!-- End of logo box --> */}
              </div>
              <div className="col-md-9 col-lg-9 col-7 position-static">
                <div className="menu-wrapper position-static">
                  <div className="header-menu position-static">
                    <div id="menu-button" onClick={() => setNavHide(!navHide)}>
                      <i className="flaticon-menu"></i>
                    </div>
                    {/* <!-- main menu --> */}
                    <ul
                      style={
                        window.innerWidth < 992
                          ? {
                              display: `${navHide ? 'block' : 'none'}`
                            }
                          : {
                              display: `${navHide ? 'block' : 'block'}`
                            }
                      }
                    >
                      <li>
                        <Link to="/">{t('home')}</Link>
                      </li>

                      <li>
                        <Link to="/service">{t('services')}</Link>
                      </li>
                      <li>
                        <Link to="/about">{t('about')}</Link>
                      </li>
                      <li>
                        <Link to="/reports">{t('reports')}</Link>
                      </li>
                      {/* <li>
                        <Link to="#!">Project</Link>
                        <ul>
                          <li>
                            <Link to="/project">Project</Link>
                          </li>
                          <li>
                            <Link to="/project-details">Project Detais</Link>
                          </li>
                        </ul>
                      </li> */}

                      <li>
                        <Link to="/contact">{t('contact')}</Link>
                      </li>
                      <li>
                        <Link to="#!">{t('language')}</Link>
                        <ul>
                          {Object.keys(lngs).map((lng) => (
                            <li
                              key={lng}
                              style={{
                                fontWeight:
                                  i18n.language === lng ? 'bold' : 'normal'
                              }}
                              // type="submit"
                              onClick={() => i18n.changeLanguage(lng)}
                            >
                              <Link to="#">{lngs[lng].nativeName}</Link>
                            </li>
                          ))}
                          {/* <li>
                            <Link to="/blog">Blog</Link>
                          </li>
                          <li>
                            <Link to="/blog-details">Blog Details</Link>
                          </li> */}
                        </ul>
                      </li>
                    </ul>
                    {/* <!-- End of main menu --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- End of the header area --> */}
    </>
  );
};
export default NavigationMenu;
