import { React } from 'react';
import SectionTitle from '../global-components/SectionTitle';
import info from './../../data/section.json';
import { useTranslation } from 'react-i18next';

const Service = () => {
  const { t } = useTranslation();

  const servicesTranslated = [
    { title: t('service1_title'), content: t('service1_text') },
    { title: t('service2_title'), content: t('service2_text') },
    { title: t('service3_title'), content: t('service3_text') },
    { title: t('service4_title'), content: t('service4_text') },
    { title: t('service5_title'), content: t('service5_text') },
    { title: t('service6_title'), content: t('service6_text') }
  ];

  let data = info.sectionData.service;

  const services = data.singleService.map((service, i) => {
    return { ...service, ...servicesTranslated[i] };
  });

  let publicUrl = process.env.PUBLIC_URL + '/';
  return (
    <>
      {/* <!--===================== service =====================--> */}

      <section className="service pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <SectionTitle
                title={t('servicesWeOffer')}
                titleContent={t('services_description')}
              />
            </div>
          </div>
          <div className="row no-gutters">
            {services.map((service, i) => {
              if (service.title) {
                return (
                  <div className="col-md-6 col-lg-4" key={i}>
                    <div
                      className={`single-service text-center wow ${service.animation}`}
                      data-wow-delay="0.3s"
                      data-wow-duration="1.5s"
                      style={{
                        background: `url(${service.image})`
                      }}
                    >
                      <div>
                        <i className={service.icon}></i>
                        <h4>{service.title}</h4>
                        <p>{service.content}</p>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </section>
      {/* <!--===================== End of service =====================--> */}
    </>
  );
};
export default Service;
