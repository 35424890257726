import { React } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Hero() {
  const { t } = useTranslation();

  return (
    <section
      className="hero-area"
      style={{ background: "url(../../assets/img/hero/hero1.jpg)" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            {/* <!--=================== hero inner area ===================--> */}

            <div className="hero-inner-content">
              <span
                className="wow fadeInUp"
                data-wow-delay="0.2s"
                data-wow-duration="1.5s"
              >
                {t("banner_subtitle")}
              </span>
              <h1
                className="wow fadeInUp"
                data-wow-delay="0.4s"
                data-wow-duration="1.5s"
              >
                {t("banner_title")}
              </h1>
              <p
                className="wow fadeInUp"
                data-wow-delay="0.6s"
                data-wow-duration="1.5s"
              >
                {t("banner_description")}
              </p>
              <div
                className="hero-action wow fadeInUp"
                data-wow-delay="0.8s"
                data-wow-duration="1.5s"
              >
                <Link to="/about" className="btn">
                  {t("about")}
                </Link>
              </div>
            </div>

            {/* <!--=================== End of hero inner area ===================--> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
