import React, { useEffect } from 'react';
import Footer from '../components/global-components/Footer';
import NavigationMenu from '../components/global-components/NavigationMenu';
import ScrollTop from '../components/global-components/BackToTop';
import Layouts from '../components/global-components/Layouts';
import PageTitle from '../components/global-components/PageTitle';
import { useTranslation } from 'react-i18next';
import info from '../data/section.json';

const ContactPage = () => {
  const { t } = useTranslation();

  let data = info.sectionData.sectionTitle.contact;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layouts pageTitle={t('reports')}>
      <NavigationMenu />
      <PageTitle
        pageTitle={t('reports')}
        background={data.background}
        pageName={t('reports')}
      />
      <h4 style={{ padding: '0 20px 40px' }}>
        <a href="assets/img/2022.pdf" target="blank">
          {t('report_2022')}
        </a>
      </h4>
      <Footer />
      <ScrollTop />
    </Layouts>
  );
};
export default ContactPage;
