/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

export default function Map() {
    return (
        <div style={{ width: '100%' }}>
            <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3048.021710789038!2d44.516238915046465!3d40.186330279392685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abce19fef5f71%3A0x307635d599d60cc!2s69%20Teryan%20St%2C%20Yerevan%200009%2C%20Armenia!5e0!3m2!1sen!2s!4v1633356158507!5m2!1sen!2s'
                width='600'
                height='450'
                style={{ width: '100%' }}
                allowfullscreen=''
                loading='lazy'
            ></iframe>
        </div>
    );
}
