import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ErrorPage from '../../page/Error';
import FaqPage from '../../page/Faq';
import Home from '../../page/Home';
import About from './../../page/About';
import ContactPage from './../../page/Contact';
import ReportsPage from './../../page/Reports';
import PricingPage from './../../page/Pricing';
import Project from './../../page/Project';
import Service from './../../page/Service';
import SingleProjectPage from './../../page/Single-project';
import TeamPage from './../../page/Team';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { client } from '../../prismic-configuration';

function AppRoute() {
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();

  useEffect(() => {
    (async () => {
      // const en = (await client.getSingle("main")).data;
      const am = (await client.getSingle('main_hy')).data;
      const ru = (await client.getSingle('main_ru')).data;

      // i18next.addResourceBundle("en", "translation", {
      //   ...i18n.store.data.en.translation,
      //   ...en,
      // });
      i18next.addResourceBundle('ru', 'translation', {
        ...i18n.store.data.ru.translation,
        ...ru
      });
      i18next.addResourceBundle('am', 'translation', {
        ...i18n.store.data.am.translation,
        ...am
      });

      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <div className="swiper-lazy-preloader"></div>;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path={`/about`} component={About} />
        <Route path={`/service`} component={Service} />
        <Route path={`/project`} component={Project} />
        <Route
          path={`${process.env.PUBLIC_URL}/project-details/:id`}
          component={SingleProjectPage}
        />
        <Route path={`/project-details`} component={SingleProjectPage} />
        <Route path={`/faq`} component={FaqPage} />
        <Route path={`/team`} component={TeamPage} />
        <Route path="/pricing" component={PricingPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/reports" component={ReportsPage} />
        <Route path="*" component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  );
}
export default AppRoute;
