import React, { useEffect } from 'react';
import Footer from '../components/global-components/Footer';
import NavigationMenu from '../components/global-components/NavigationMenu';
import ScrollTop from './../components/global-components/BackToTop';
import Layouts from './../components/global-components/Layouts';
import PageTitle from './../components/global-components/PageTitle';
import MapContactWrapperV2 from './../components/section-components/MapContactWrapper-v2';
import { useTranslation } from 'react-i18next';
import info from './../data/section.json';

const ContactPage = () => {
  const { t } = useTranslation();

  let data = info.sectionData.sectionTitle.contact;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layouts pageTitle={t('contact')}>
      <NavigationMenu />
      <PageTitle
        pageTitle={t('contact')}
        background={data.background}
        pageName={t('contact')}
      />
      <MapContactWrapperV2 />
      <Footer />
      <ScrollTop />
    </Layouts>
  );
};
export default ContactPage;
