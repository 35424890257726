import { React } from "react";
const Layouts = (props) => {
  return (
    <>
      <title> {props.pageTitle} | Export Invest</title>
      <div>{props.children}</div>
    </>
  );
};
export default Layouts;
