import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { RichText } from 'prismic-reactjs';

function About() {
  let imagealt = 'About construction';
  const { t, i18n } = useTranslation();

  return (
    <>
      {/* <!--====================== about area ======================--> */}

      <section className="about pb-120 mt-120">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              {/* <!--====================== about content ======================--> */}
              <div className="about-content">
                <div className="about-header">
                  <span
                    className="wow fadeInUp"
                    data-wow-delay="0.1s"
                    data-wow-duration="1.5s"
                  >
                    {t('whychooseus_subtitle')}
                  </span>
                  <h2
                    className="wow fadeInUp"
                    data-wow-delay="0.2s"
                    data-wow-duration="1.5s"
                  >
                    {t('whychooseus_title')}
                  </h2>
                </div>
                <p
                  className="meta-list wow fadeInUp"
                  data-wow-delay="0.4s"
                  data-wow-duration="1.5s"
                >
                  <ul>
                    {i18n.store.data[
                      i18n.language
                    ]?.translation.whychooseus_description.map((item, i) => {
                      if (item.type === 'list-item') {
                        return (
                          <li key={i}>
                            <span>
                              <i className="flaticon-check"></i>
                            </span>
                            {RichText.render([item])}
                          </li>
                        );
                      }
                      return RichText.render([item]);
                    })}
                  </ul>
                </p>
              </div>
              {/* <!--====================== End of about content ======================--> */}
            </div>
            <div className="col-lg-5 offset-lg-1 order-lg-1 order-first">
              {/* <!--====================== about image ======================--> */}
              <div
                className="about-image-content wow fadeInRight"
                data-wow-delay="0.3s"
                data-wow-duration="1.5s"
              >
                <div className="image-wrap">
                  <img src="../../assets/img/about.jpg" alt={imagealt} />
                </div>
                <span className="line"></span>
              </div>
              {/* <!--====================== End of about image ======================--> */}
            </div>
          </div>
        </div>
      </section>

      {/* <!--====================== End of about area ======================--> */}
    </>
  );
}
export default About;
