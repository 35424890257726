import React, { useState } from 'react';
import info from './../../data/section.json';
import { useTranslation } from 'react-i18next';
import emailjs, { init } from 'emailjs-com';
init('user_GvEVRfVsu7kH50JOB7RiT');

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [sub, setSub] = useState('');
  const [message, setMessage] = useState('');
  const [mailSuccess, setMailSuccess] = useState('');
  const { t } = useTranslation();

  const data = info.sectionData.contact;

  const onHandSubmit = (e) => {
    e.preventDefault();
    fetchApi();
  };

  function fetchApi() {
    var templateParams = {
      name,
      subject: sub,
      email,
      phone,
      message
    };

    emailjs.send('service_er2ab6k', 'template_1gufcsh', templateParams).then(
      function (response) {
        setMailSuccess(true);
        setName('');
        setEmail('');
        setPhone('');
        setSub('');
        setMessage('');
      },
      function (error) {
        console.log('FAILED...', error);
      }
    );
    // axios({
    //   method: "POST",
    //   url: "http://donisr.w3webscript.com/send/index.php",
    //   data: this.state,
    // }).then((res) => {
    //   console.log(res);
    //   if (res.status === 200) {
    //     this.setState({
    //       mailSuccess: true,
    //     });
    //   }
    // });
  }

  const fieldsTranslated = [
    {
      title: t('address'),
      contentOne: t('address_1'),
      contentTwo: t('address_2')
    },
    { title: t('phone'), contentOne: t('phone_1'), contentTwo: t('phone_2') },
    { title: t('email'), contentOne: t('email_1'), contentTwo: t('email_2') }
  ];

  const fields = data.getAdvice.address.map((field, i) => {
    return { ...field, ...fieldsTranslated[i] };
  });

  return (
    <div
      className="contact-information wow fadeInUp"
      data-wow-delay="0.2s"
      data-wow-duration="1.5s"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="information-inner">
              <div className="row">
                <div className="col-lg-5">
                  <div className="address-inner">
                    {/* <!--====================== address information ======================--> */}
                    <div className="address-information">
                      <ul>
                        {fields.map((address, i) => {
                          return (
                            <li className="media" key={i}>
                              <span className="icon">
                                <i className={address.icon}></i>
                              </span>
                              <div className="media-body">
                                <h4>{address.title}</h4>
                                <p>{address.contentOne}</p>
                                <p>{address.contentTwo}</p>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {/* <!--====================== End of address information ======================--> */}
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="form-content">
                    {/* <!--====================== header ======================--> */}
                    <div className="headre">
                      <h2>{t('formTitle')}</h2>
                    </div>
                    {/* <!--====================== End of header ======================--> */}

                    <div className="form-information">
                      <form onSubmit={onHandSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="name">
                              <input
                                onChange={(e) => setName(e.target.value)}
                                name="name"
                                value={name}
                                type="text"
                                placeholder={t('name')}
                                required
                              />
                              <span>
                                <i className="flaticon-user"></i>
                              </span>
                            </label>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="email">
                              <input
                                onChange={(e) => setEmail(e.target.value)}
                                name="email"
                                value={email}
                                type="email"
                                placeholder={t('email')}
                                required
                              />
                              <span>
                                <i className="flaticon-envelope"></i>
                              </span>
                            </label>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="phone">
                              <input
                                onChange={(e) => setPhone(e.target.value)}
                                name="phone"
                                value={phone}
                                type="tel"
                                placeholder={t('phone')}
                              />
                              <span>
                                <i className="flaticon-phone-call-1"></i>
                              </span>
                            </label>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="subject">
                              <input
                                onChange={(e) => setSub(e.target.value)}
                                name="sub"
                                value={sub}
                                type="text"
                                placeholder={t('subject')}
                              />
                              <span>
                                <i className="flaticon-edit-button"></i>
                              </span>
                            </label>
                          </div>

                          <div className="col-md-12">
                            <label htmlFor="msg">
                              <textarea
                                onChange={(e) => setMessage(e.target.value)}
                                name="message"
                                value={message}
                                placeholder={t('message')}
                                required
                              ></textarea>
                              <span>
                                <i className="flaticon-speech-bubble"></i>
                              </span>
                            </label>
                          </div>

                          <div className="col-md-12">
                            <div className="form-action">
                              <button type="submit" className="btn">
                                {t('send')}
                              </button>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="sending-information">
                              {mailSuccess === true ? (
                                <span>Mail Sent Successfully</span>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
