import React, { useEffect } from 'react';
import PageTitle from '../components/global-components/PageTitle';
import ScrollTop from './../components/global-components/BackToTop';
import Footer from './../components/global-components/Footer';
import Layouts from './../components/global-components/Layouts';
import About from './../components/section-components/About';
import NavigationMenu from './../components/global-components/NavigationMenu';
import { useTranslation } from 'react-i18next';

import info from './../data/section.json';

const AboutPage = () => {
  const { t } = useTranslation();

  let data = info.sectionData.sectionTitle.about;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layouts pageTitle={t('about')}>
      <NavigationMenu />
      <PageTitle
        pageTitle={t('about')}
        background={data.background}
        pageName={t('about')}
      />
      <About />
      <Footer />
      <ScrollTop />
    </Layouts>
  );
};
export default AboutPage;
