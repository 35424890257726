import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { useTranslation } from 'react-i18next';

import info from './../../data/section.json';
let data = info.sectionData.counter;

const Counter = () => {
  const [didViewCountUp, setDidViewCountUp] = useState(false);
  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };
  const { t } = useTranslation();

  const singleCounters = [
    { value: t('stats1_number'), title: t('stats1_text') },
    { value: t('stats2_number'), title: t('stats2_text') },
    { value: t('stats3_number'), title: t('stats3_text') },
    { value: t('stats4_number'), title: t('stats4_text') }
  ];

  console.log(t('stats1_number'));

  return (
    <>
      {/* <!--================ counter  ================--> */}

      <section
        className="counter mb-120"
        style={{ background: `url(../../assets/img/counter.png)` }}
      >
        <div className="container-fluid">
          <div className="row">
            {data.singleCounter.map((singleCount, i) => {
              if (singleCounters[i].value) {
                return (
                  <div className="col-lg-3 col-md-6" key={i}>
                    {/* <!--================ single counter ================--> */}
                    <div className="single-counter">
                      <span>
                        <i className={singleCount.icon}></i>
                      </span>
                      <div className="counter-content">
                        <VisibilitySensor
                          onChange={onVisibilityChange}
                          offset={{ top: 10 }}
                          delayedCall
                        >
                          <CountUp
                            end={didViewCountUp ? singleCounters[i].value : 0}
                          />
                        </VisibilitySensor>

                        <span>+</span>
                        <h3>{singleCounters[i].title}</h3>
                      </div>
                    </div>
                    {/* <!--================ End of single counter ================--> */}
                  </div>
                );
              }
              return <></>;
            })}
          </div>
        </div>
      </section>

      {/* <!--================ End of counter  ================--> */}
    </>
  );
};

export default Counter;
