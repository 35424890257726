import React, { useEffect } from 'react';
import Footer from '../components/global-components/Footer';
import Service from '../components/section-components/Service';
import ScrollTop from './../components/global-components/BackToTop';
import Layouts from './../components/global-components/Layouts';
import NavigationMenuV3 from './../components/global-components/NavigationMenu';
import PageTitle from './../components/global-components/PageTitle';
import { useTranslation } from 'react-i18next';

import info from './../data/section.json';

const ServicePage = () => {
  const { t } = useTranslation();

  let data = info.sectionData.sectionTitle.service;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layouts pageTitle={t('services')}>
      <NavigationMenuV3 />
      <PageTitle
        pageTitle={t('services')}
        background={data.background}
        pageName={t('services')}
      />
      <Service />
      <Footer />
      <ScrollTop />
    </Layouts>
  );
};
export default ServicePage;
